<template>
  <div>
    <b-nav-item-dropdown
      right
      class="dropdown-notification"
      menu-class="dropdown-menu-media"
      ref="userdd"
      @show="getPause"
    >
      <!-- toggle-class="d-flex align-items-center dropdown-user-link"
      class="dropdown-user" -->
      <template #button-content>
        <!-- <div class="d-sm-flex d-none user-nav">
          <p class="user-name font-weight-bolder mb-0">
            {{ userData.fullName || userData.username }}
          </p>
          <span class="user-status">{{ userData.roles }}</span>
        </div> -->

        <b-avatar
          size="40"
          variant="light-primary"
          badge
          class="badge-minimal"
          badge-variant="success"
          :src="$store.state.loggedUser.avatar"
        >
          <!-- <feather-icon icon="UserIcon" size="22" /> -->
        </b-avatar>
      </template>

      <li class="dropdown-menu-header">
        <div class="dropdown-header d-flex align-items-center">
          <b-avatar
            size="40"
            variant="light-primary"
            :src="$store.state.loggedUser.avatar"
            class="mr-50"
          />
          <div>
            <h4 class="notification-title mb-0">
              {{ $store.state.loggedUser.name }}
            </h4>
            <p class="mb-0">
              {{ $store.state.userData.username }}
            </p>
          </div>
        </div>
      </li>

      <b-dropdown-item
        v-if="showWa && dwObj && dwObj.valueBool && dwObj.value"
        link-class="d-flex align-items-center justify-content-between"
      >
        <div>
          <img
            src="https://cdn.cloudious.net/file-1676700040613-583826971.svg"
            class="mr-50"
            alt=""
            width="40"
          />
          <span>WhatsApp</span>
        </div>
        <div v-if="status && status.toLowerCase() == 'connected'">
          <template
            v-if="
              pauseObj &&
              pauseObj.value &&
              pauseObj.value.toLowerCase() == 'true'
            "
          >
            <b-button
              v-if="pauseState"
              variant="flat-dark"
              class="btn-icon"
              pill
              v-b-tooltip.hover.top
              title="Resume WhatsApp"
              @click.stop.prevent="startWA()"
              :disabled="pausing"
            >
              <b-spinner v-if="pausing" small />
              <feather-icon v-else icon="PlayIcon" size="16" />
            </b-button>

            <b-button
              v-else
              variant="flat-dark"
              class="btn-icon"
              pill
              v-b-tooltip.hover.top
              title="Pause WhatsApp"
              @click.stop.prevent="pauseWA()"
              :disabled="pausing"
            >
              <b-spinner v-if="pausing" small />
              <feather-icon v-else icon="PauseIcon" size="16" />
            </b-button>
          </template>

          <b-button
            variant="flat-danger"
            class="btn-icon"
            pill
            v-b-tooltip.hover.top
            title="Logout WhatsApp"
            @click="logoutWA()"
            :disabled="loader || pausing"
          >
            <b-spinner v-if="loader" small />
            <feather-icon v-else icon="LogOutIcon" size="16" />
          </b-button>
        </div>
        <div v-else>
          <b-button
            variant="flat-success"
            class="btn-icon"
            pill
            v-b-tooltip.hover.top
            title="Connect WhatsApp"
            @click="$emit('show-sidebar')"
            :disabled="loader || pausing"
          >
            <b-spinner v-if="loader" small />
            <feather-icon v-else icon="CheckSquareIcon" size="16" />
          </b-button>
        </div>
      </b-dropdown-item>
      <b-dropdown-item link-class="d-flex align-items-center" @click="change">
        <b-avatar size="40" variant="light-secondary" class="mr-50">
          <feather-icon size="18" icon="LockIcon" />
        </b-avatar>
        <span>Change Password</span>
      </b-dropdown-item>
      <b-dropdown-item link-class="d-flex align-items-center" @click="logout">
        <b-avatar size="40" variant="light-secondary" class="mr-50">
          <feather-icon size="18" icon="LogOutIcon" />
        </b-avatar>
        <span>Logout</span>
      </b-dropdown-item>

      <!-- 
      <b-dropdown-item
        :to="{ name: 'apps-email' }"
        link-class="d-flex align-items-center"
      >
        <feather-icon
          size="16"
          icon="MailIcon"
          class="mr-50"
        />
        <span>Inbox</span>
      </b-dropdown-item>
      <b-dropdown-item
        :to="{ name: 'apps-todo' }"
        link-class="d-flex align-items-center"
      >
        <feather-icon
          size="16"
          icon="CheckSquareIcon"
          class="mr-50"
        />
        <span>Task</span>
      </b-dropdown-item>
      <b-dropdown-item
        :to="{ name: 'apps-chat' }"
        link-class="d-flex align-items-center"
      >
        <feather-icon
          size="16"
          icon="MessageSquareIcon"
          class="mr-50"
        />
        <span>Chat</span>
      </b-dropdown-item> -->

      <!-- <b-dropdown-divider /> -->
      <!-- 
      <b-dropdown-item
        :to="{ name: 'pages-account-setting' }"
        link-class="d-flex align-items-center"
      >
        <feather-icon
          size="16"
          icon="SettingsIcon"
          class="mr-50"
        />
        <span>Settings</span>
      </b-dropdown-item>
      <b-dropdown-item
        :to="{ name: 'pages-pricing' }"
        link-class="d-flex align-items-center"
      >
        <feather-icon
          size="16"
          icon="CreditCardIcon"
          class="mr-50"
        />
        <span>Pricing</span>
      </b-dropdown-item>
      <b-dropdown-item
        :to="{ name: 'pages-faq' }"
        link-class="d-flex align-items-center"
      >
        <feather-icon
          size="16"
          icon="HelpCircleIcon"
          class="mr-50"
        />
        <span>FAQ</span>
      </b-dropdown-item> -->
    </b-nav-item-dropdown>
  </div>
</template>

<script>
import {
  BNavItemDropdown,
  BDropdownItem,
  BDropdownDivider,
  BAvatar,
  BSidebar,
  BRow,
  BCol,
  BButton,
  BSpinner,
  BFormGroup,
  BFormInput,
  BImg,
  VBTooltip,
} from "bootstrap-vue";
import { initialAbility } from "@/libs/acl/config";
import useJwt from "@/auth/jwt/useJwt";
import { avatarText } from "@core/utils/filter";
import { $themeConfig, $themeColors } from "@themeConfig";
import { mapActions, mapGetters } from "vuex";
import axios from "axios";

export default {
  components: {
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,
    BSidebar,
    BRow,
    BCol,
    BButton,
    BSpinner,
    BFormGroup,
    BFormInput,
    BImg,
  },
  props: {
    dwObj: {
      type: Object,
      default: null,
    },
    appObj: {
      type: Object,
      default: null,
    },
    pauseObj: {
      type: Object,
      default: null,
    },
    status: {
      type: String,
      default: "",
    },
    showWa: {
      type: Boolean,
      default: false,
    },
    loader: {
      type: Boolean,
      default: false,
    },
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  setup() {
    const { appLogoImage } = $themeConfig.app;
    return {
      appLogoImage,
    };
  },
  data() {
    return {
      userData: this.$store.state.userData,
      avatarText,
      pressed: true,
      request: false,
      logo: this.$store.state.appConfig.appLogoImage,
      pausing: false,
      pauseState: false,
    };
  },
  mounted() {
    window.addEventListener("keydown", this.handleEvent);
  },
  beforeDestroy() {
    window.removeEventListener("keydown", this.handleEvent);
  },
  methods: {
    ...mapActions({ get: "get", post: "post", put: "put", delete: "delete" }),

    handleEvent(event) {
      // console.log(event.key);
      if (event.ctrlKey && event.keyCode === 76) {
        event.preventDefault();
        if (this.pressed) {
          this.pressed = false;
          this.logout();
        }
      }
    },
    async getPause() {
      if (
        this.showWa &&
        this.dwObj &&
        this.dwObj.valueBool &&
        this.dwObj.value
      ) {
        this.pausing = true;
        let config = {
          method: "post",
          url: "https://" + this.$store.state.WAdomain + "/getPauseStatus",
          headers: {
            "Content-Type": "application/json",
            "x-deploy-password": "nkEmwEUJZgC22iNb8zF2HqTo",
          },
          data: {
            clientID: this.$store.state.clientID,
            cID: this.$store.state.userData.cId,
          },
        };
        try {
          let response = await axios(config);
          // console.log(response.data);
          if (response.data.status == "success") {
            this.pauseState = response.data.pausedByUser;
          }
        } catch (error) {
          console.log(error);
        } finally {
          this.pausing = false;
        }
      }
    },
    async pauseWA() {
      this.pausing = true;
      let config = {
        method: "post",
        url: "https://" + this.$store.state.WAdomain + "/setPause",
        headers: {
          "Content-Type": "application/json",
          "x-deploy-password": "nkEmwEUJZgC22iNb8zF2HqTo",
        },
        data: {
          clientID: this.$store.state.clientID,
          cID: this.$store.state.userData.cId,
        },
      };
      try {
        let response = await axios(config);
        // console.log(response.data);
        if (response.data.status == "success") {
          this.$bvToast.toast("WhatsApp paused successfully.", {
            title: "Success!",
            variant: "success",
            toaster: "b-toaster-top-right",
            solid: true,
          });
          this.pauseState = true;
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.pausing = false;
        var elem = this.$refs.userdd;
        if (elem) elem.hide();
      }
      // let result = await Swal.fire({
      //   title: "Are you sure?",
      //   text: `Do you want to pause WhatsApp?`,
      //   icon: "warning",
      //   showCancelButton: true,
      //   confirmButtonColor: "#d33",
      //   cancelButtonColor: "#3085d6",
      //   confirmButtonText: "Yes",
      // });
      // if (result.isConfirmed) {
      // }
    },
    async startWA() {
      this.pausing = true;
      let config = {
        method: "post",
        url: "https://" + this.$store.state.WAdomain + "/setResume",
        headers: {
          "Content-Type": "application/json",
          "x-deploy-password": "nkEmwEUJZgC22iNb8zF2HqTo",
        },
        data: {
          clientID: this.$store.state.clientID,
          cID: this.$store.state.userData.cId,
        },
      };
      try {
        let response = await axios(config);
        // console.log(response.data);
        if (response.data.status == "success") {
          this.$bvToast.toast("WhatsApp resumed successfully.", {
            title: "Success!",
            variant: "success",
            toaster: "b-toaster-top-right",
            solid: true,
          });
          this.pauseState = false;
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.pausing = false;
        var elem = this.$refs.userdd;
        if (elem) elem.hide();
      }
    },
    async logoutWA() {
      this.$emit("set-loader", true);
      let config = {
        method: "post",
        url: this.$store.state.setupUrl + "/logout",
        headers: {
          "Content-Type": "application/json",
          "x-deploy-password": "nkEmwEUJZgC22iNb8zF2HqTo",
        },
        data: {
          applicationId: this.$store.state.appID,
        },
      };
      try {
        let response = await axios(config);
        // console.log(response.data);
        // if (response.data.success == true) {
        //   this.$bvToast.toast("WhatsApp logout successfully.", {
        //     title: "Success!",
        //     variant: "success",
        //     toaster: "b-toaster-top-right",
        //     solid: true,
        //   });
        // }
      } catch (error) {
        console.log(error);
      }
    },
    logout() {
      // Remove userData from localStorage
      // ? You just removed token from localStorage. If you like, you can also make API call to backend to blacklist used token
      localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName);
      localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName);
      this.$store.commit("appConfig/UPDATE_CONTENT_WIDTH", "full");
      // this.$store.commit("setMenu", []);
      // this.$store.commit("setCurrentMenu", {});

      // Remove userData from localStorage
      localStorage.removeItem("userData");

      // Reset ability
      this.$ability.update(initialAbility);
      this.$store.commit("setPassword", "");
      this.$store.commit("setEncPassword", "");
      this.$store.commit("setDBList", []);
      // Redirect to login page
      this.$router.push({ name: "auth-login" });
    },
    change() {
      this.$router.push("/reset-password");
    },
  },
};
</script>
